h1{
    color: red;
    text-align: center;
}
.item{
    min-height: 40rem;
    padding: 40px;
    width: 30rem;
}
.item img{
    width: 20rem;
    height: 23rem;
    border-radius: 2rem;
    pointer-events: none;
}
.inner-caousel{
    display: flex;
    /* background: rgb(177, 173, 169); */
}
.carousel{
    cursor: grab;
    overflow: hidden;
}
h2{
    color: darkorange;
    text-align: right;
}
/* Media query for mobile responsive */
@media screen and (max-width :580px){
    .item img{
        width: 10rem;
    }
    h1{
        margin-top: 3rem;
    }
}

    


